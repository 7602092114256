import React from 'react';
import '../../styles/Privacy.css';

function Privacy() {
    return (
        <div className="privacy-container">
            <h1>Politique de Confidentialité</h1>

            <section>
                <h2>1. Introduction</h2>
                <p>
                    Chez SkanEat, la confidentialité de vos données est une priorité. Cette politique de confidentialité décrit comment
                    nous collectons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre site ou nos services.
                </p>
            </section>

            <section>
                <h2>2. Les Données que Nous Collectons</h2>
                <p>Nous pouvons collecter les types de données suivants :</p>
                <ul>
                    <li><strong>Informations personnelles :</strong> Nom, prénom, adresse e-mail, numéro de téléphone, etc.</li>
                    <li><strong>Informations techniques :</strong> Adresse IP, type de navigateur, pages visitées, et autres données
                        relatives à votre interaction avec notre site.
                    </li>
                    <li><strong>Données financières :</strong> Informations de paiement pour le traitement des transactions.</li>
                </ul>
            </section>

            <section>
                <h2>3. Utilisation des Données</h2>
                <p>Vos données sont utilisées pour :</p>
                <ul>
                    <li>Fournir nos services, y compris la création d’applications personnalisées pour la restauration.</li>
                    <li>Communiquer avec vous concernant vos commandes, devis ou demandes de support.</li>
                    <li>Améliorer notre site et nos services grâce à l'analyse des comportements des utilisateurs.</li>
                    <li>Respecter nos obligations légales et réglementaires.</li>
                </ul>
            </section>

            <section>
                <h2>4. Partage des Données</h2>
                <p>
                    Nous ne partageons vos données personnelles qu'avec des tiers dans les cas suivants :
                </p>
                <ul>
                    <li>Avec votre consentement explicite.</li>
                    <li>Avec nos prestataires de services pour l'exécution de nos obligations (hébergement, paiements, etc.).</li>
                    <li>Pour répondre à des obligations légales ou protéger nos droits en cas de litige.</li>
                </ul>
            </section>

            <section>
                <h2>5. Sécurité des Données</h2>
                <p>
                    Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos données contre tout accès non
                    autorisé, perte ou divulgation. Toutefois, aucune méthode de transmission ou de stockage électronique n'est totalement
                    sécurisée, et nous ne pouvons garantir une sécurité absolue.
                </p>
            </section>

            <section>
                <h2>6. Vos Droits</h2>
                <p>Conformément à la législation en vigueur, vous disposez des droits suivants concernant vos données personnelles :</p>
                <ul>
                    <li><strong>Droit d'accès :</strong> Vous pouvez demander à consulter les données que nous détenons à votre sujet.</li>
                    <li><strong>Droit de rectification :</strong> Vous pouvez demander la correction de vos données inexactes ou incomplètes.</li>
                    <li><strong>Droit à l'effacement :</strong> Vous pouvez demander la suppression de vos données, sous réserve de nos
                        obligations légales.
                    </li>
                    <li><strong>Droit à la portabilité :</strong> Vous pouvez demander à recevoir vos données dans un format structuré.
                    </li>
                    <li><strong>Droit d'opposition :</strong> Vous pouvez vous opposer à certains traitements de vos données.</li>
                </ul>
                <p>
                    Pour exercer ces droits, contactez-nous via le <a href="/contact">formulaire de contact</a>.
                </p>
            </section>

            <section>
                <h2>7. Cookies</h2>
                <p>
                    Notre site utilise des cookies pour améliorer votre expérience utilisateur et analyser l'utilisation du site. Vous
                    pouvez configurer votre navigateur pour refuser les cookies ou être averti avant leur installation.
                </p>
            </section>

            <section>
                <h2>8. Modifications de la Politique</h2>
                <p>
                    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications
                    seront publiées sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement
                    cette politique.
                </p>
            </section>

            <section>
                <h2>9. Contact</h2>
                <p>
                    Pour toute question ou préoccupation concernant cette politique de confidentialité, vous pouvez nous contacter via le <a href="/contact">formulaire de contact</a>.
                </p>
            </section>
        </div>
    );
}

export default Privacy;
