import '../styles/ToTopBtn.css';
import { FaArrowUp } from 'react-icons/fa';

function ToTopBtn() {

    return (
        <div>
            <button className="ToTopBtn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <FaArrowUp />
            </button>
        </div>
    );
};

export default ToTopBtn;
