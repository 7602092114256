import React from 'react';
import '../../styles/SiteMap.css';

function SiteMap() {
    return (
        <div className='sitemap-container'>
            <ul>
                <li><a href='/'>Accueil</a></li>
                <li><a href='/features'>Fonctionnalités</a></li>
                <li><a href='/pricing'>Tarifs</a></li>
                <li><a href='/faq'>FAQ</a></li>
                <li><a href='/contact'>Contact</a></li>
                {/* <li><a href='/partners'>Partenaires</a></li> */}
                <li><a href='/cgv'>Conditions Générales de Vente</a></li>
                <li><a href='/terms'>Conditions d'Utilisation</a></li>
                <li><a href='/privacy'>Politique de Confidentialité</a></li>
                <li><a href='/cookies'>Politique Relative à l'Utilisation des Cookies</a></li>
                <li><a href='/sitemap'>Plan du Site</a></li>
            </ul>
        </div>
    );
}

export default SiteMap;
