import React from 'react';
import '../../styles/Terms.css';

function Terms() {
    return (
        <div className="terms-container">
            <h1>Conditions d'Utilisation</h1>

            <section>
                <h2>1. Introduction</h2>
                <p>
                    Bienvenue sur le site de SkanEat. En accédant à notre site et en utilisant nos services, vous acceptez
                    les présentes Conditions d'Utilisation dans leur intégralité. Si vous n'acceptez pas ces termes, nous vous
                    invitons à ne pas utiliser notre site.
                </p>
            </section>

            <section>
                <h2>2. Définitions</h2>
                <ul>
                    <li><strong>Site :</strong> Le site web www.skaneat.com.</li>
                    <li><strong>Utilisateur :</strong> Toute personne accédant au site ou utilisant les services de SkanEat.</li>
                    <li><strong>Services :</strong> Les fonctionnalités et offres disponibles sur le site, y compris la création d’applications web pour la restauration.</li>
                </ul>
            </section>

            <section>
                <h2>3. Utilisation du Site</h2>
                <p>
                    Le site est destiné à fournir des informations sur nos services et à permettre aux utilisateurs de nous contacter
                    pour des devis. Vous vous engagez à utiliser le site de manière légale, en respectant les droits des autres
                    utilisateurs et les lois en vigueur.
                </p>
                <p>Il est strictement interdit :</p>
                <ul>
                    <li>D'utiliser le site à des fins frauduleuses ou illégales.</li>
                    <li>D'endommager, perturber ou compromettre la sécurité du site.</li>
                    <li>De collecter ou d'utiliser les données personnelles d'autres utilisateurs sans autorisation.</li>
                </ul>
            </section>

            <section>
                <h2>4. Propriété Intellectuelle</h2>
                <p>
                    Tout le contenu du site, y compris les textes, images, logos, graphismes, et logiciels, est protégé par les lois
                    sur la propriété intellectuelle. Vous ne pouvez pas reproduire, distribuer ou modifier ce contenu sans notre autorisation
                    écrite préalable.
                </p>
            </section>

            <section>
                <h2>5. Responsabilité</h2>
                <p>
                    SkanEat met tout en œuvre pour assurer la disponibilité et le bon fonctionnement du site. Toutefois, nous ne pouvons
                    garantir que le site sera exempt de bugs ou d'interruptions. En aucun cas, SkanEat ne pourra être tenu responsable des
                    dommages directs ou indirects résultant de l'utilisation du site.
                </p>
            </section>

            <section>
                <h2>6. Protection des Données Personnelles</h2>
                <p>
                    Nous respectons votre vie privée et traitons vos données personnelles conformément à notre <a href="/privacy">Politique de Confidentialité</a>.
                    En utilisant notre site, vous acceptez le traitement de vos données conformément à cette politique.
                </p>
            </section>

            <section>
                <h2>7. Liens vers des Sites Tiers</h2>
                <p>
                    Le site peut contenir des liens vers des sites tiers. Ces liens sont fournis à titre informatif uniquement. SkanEat
                    n'est pas responsable du contenu ou des pratiques de confidentialité de ces sites tiers.
                </p>
            </section>

            <section>
                <h2>8. Modifications des Conditions d'Utilisation</h2>
                <p>
                    SkanEat se réserve le droit de modifier les présentes Conditions d'Utilisation à tout moment. Les modifications
                    entreront en vigueur dès leur publication sur le site. Nous vous invitons à consulter régulièrement cette page
                    pour rester informé des éventuels changements.
                </p>
            </section>

            <section>
                <h2>9. Loi Applicable et Litiges</h2>
                <p>
                    Les présentes Conditions d'Utilisation sont régies par le droit français. En cas de litige, les parties s'engagent
                    à rechercher une solution amiable avant de saisir les juridictions compétentes.
                </p>
            </section>

            <section>
                <h2>10. Contact</h2>
                <p>
                    Pour toute question relative aux présentes Conditions d'Utilisation, vous pouvez nous contacter via le <a href="/contact">formulaire de contact</a>.
                </p>
            </section>
        </div>
    );
}

export default Terms;
