import React from 'react';
import '../styles/Pricing.css';

function Pricing() {
    const pricing = [
        { text: "Mise en place du menu interactif via QR-Code entièrement personnalisable", basic: true, standard: true, premium: true, custom: true, launch: true },
        { text: "Logiciel clé en main avec les fonctionnalités administrateurs", basic: true, standard: true, premium: true, custom: true, launch: true },
        { text: "Base de données", basic: true, standard: true, premium: true, custom: true, launch: true },
        { text: "Nom de sous-domaine personnalisable", basic: true, standard: true, premium: true, custom: true, launch: true },
        { text: "Avis des clients", basic: true, standard: true, premium: true, custom: true, launch: true },
        { text: "Paiement en ligne", basic: true, standard: true, premium: true, custom: true, launch: true },
        { text: "Assistance 5j/7", basic: true, standard: true, premium: true, custom: true, launch: true },
        { text: "Assistance 7j/7", basic: false, standard: true, premium: true, custom: true, launch: true },
        { text: "Menu accessible via puce NFC", basic: false, standard: true, premium: true, custom: true, launch: true },
        { text: "Formulaire de commande à emporter", basic: false, standard: true, premium: true, custom: true, launch: true },
        { text: "Système de suivi de commande en temps réel", basic: false, standard: true, premium: true, custom: true, launch: true },
        { text: "Gestion de réservation", basic: false, standard: false, premium: true, custom: true, launch: true },
        // { text: "Formaulaire de commande Click & Collect", basic: false, standard: false, premium: true, custom: true },
        // { text: "Module de fidélité", basic: false, standard: false, premium: true, custom: true },
        // { text: "Créez et gérez des offres spéciales (happy hour)", basic: false, standard: false, premium: true, custom: true},
        { text: "Menu bilingue (français, anglais)", basic: false, standard: false, premium: true, custom: true, launch: true },
        // { text: "Menu multilingue (français, anglais, italien, allemand, japonais, russe)", basic: false, standard: false, premium: true},
        { text: "Fonctionnalités personnalisées selon vos besoins", basic: false, standard: false, premium: false, custom: true, launch: true },
    ];

    return (
        <div>
            <h2 >Tarifs TTC (12 mois d'engagement minimum)</h2>
            <div className="pricing-container">
                <div className="pricing-plan">
                    <h3 >Offre de lancement</h3>
                    <strong><p>À partir de 499€</p></strong>
                    <div className="bottom-div">
                        <p >Puis 90€ / mois</p>
                    </div>
                    <div className="pricing-list">
                        {pricing.map((pricing, index) => (
                            <p key={index} className="pricing-item">
                                <span>{pricing.launch ? "✅" : "❌"}</span>{" "}
                                <span >{pricing.text}</span>
                            </p>
                        ))}
                    </div>
                </div>


                {/* <div className="pricing-plan">
                    <h3 >Pack Basique</h3>
                    <strong><p>À partir de 499€</p></strong>
                    <div className="bottom-div">
                        <p >Puis 129€ / mois</p>
                    </div>
                    <div className="pricing-list">
                        {pricing.map((pricing, index) => (
                            <p key={index} className="pricing-item">
                                <span>{pricing.basic ? "✅" : "❌"}</span>{" "}
                                <span >{pricing.text}</span>
                            </p>
                        ))}
                    </div>
                </div>

                <div className="pricing-plan">
                    <h3 >Pack Standard</h3>
                    <strong><p >À partir de 699€</p></strong>
                    <div className="bottom-div">
                        <p >Puis 179€ / mois</p>
                    </div>
                    <div className="pricing-list">
                        {pricing.map((pricing, index) => (
                            <p key={index} className="pricing-item">
                                <span>{pricing.standard ? "✅" : "❌"}</span>{" "}
                                <span >{pricing.text}</span>
                            </p>
                        ))}
                    </div>
                </div>

                <div className="pricing-plan">
                    <h3 >Pack Premium</h3>
                    <strong><p >À partir de 899€</p></strong>
                    <div className="bottom-div">
                        <p >Puis 219€ / mois</p>
                    </div>
                    <div className="pricing-list">
                        {pricing.map((pricing, index) => (
                            <p key={index} className="pricing-item">
                                <span>{pricing.premium ? "✅" : "❌"}</span>{" "}
                                <span >{pricing.text}</span>
                            </p>
                        ))}
                    </div>
                </div> */}

                <div className="pricing-plan">
                    <h3 >Pack Sur Mesure</h3>
                    <strong><p >Sur devis uniquement</p></strong>
                    <div className="bottom-div">
                        <a href="/contact" className="contact-button" >
                            Prendre contact
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pricing;
