import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import FAQ from './pages/FAQ';
import Terms from './pages/utils/Terms';
import CGV from './pages/utils/CGV';
import Privacy from './pages/utils/Privacy';
import Cookies from './pages/utils/Cookies';
import Loader from './components/Loader';
import ToTopBtn from './components/ToTopBtn';
import SiteMap from './pages/utils/SiteMap';
import './App.css';
import translateFR from './Assets/translateFR.png';
import translateEN from './Assets/translateEN.png';
import CookieConsent from './CookieConsent';
import { setCookie, getCookie } from './components/CookieManager';
import { CookieConsentProvider } from './components/CookieConsentContext';
import Partners from './pages/Partners';

function App() {
    const [language, setLanguage] = useState("fr");
    const [translateIcon, setTranslateIcon] = useState(translateEN);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        const savedLanguage = getCookie('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
            setTranslateIcon(savedLanguage === "en" ? translateFR : translateEN);
        }
    }, [language]);

    const isCookieFreePage = () => {
        return window.location.pathname === '/cookies';
    };

    useEffect(() => {
        const userPreference = getCookie('skaneat_cookies');
        if (userPreference) {
            setCookiesAccepted(true);
        }

        if (isCookieFreePage() || cookiesAccepted) {
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }, [cookiesAccepted]);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const handleHashChange = () => {
            window.scrollTo(0, 0);
            window.location.reload();
        };

        window.addEventListener('hashchange', handleHashChange);

        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    const handleUserPreference = async (preference) => {
        await setCookie('user_preference', preference);
        await setCookie('language', language);
        setCookiesAccepted(true);
    };

    const toggleLanguage = () => {
        const newLanguage = language === "en" ? "fr" : "en";
        setLanguage(newLanguage);
        setTranslateIcon(prevIcon => (prevIcon === translateFR ? translateEN : translateFR));
    };

    return (
        <CookieConsentProvider>
            <Router>
                <Loader load={isLoading} />
                <div style={{ 
                    filter: !cookiesAccepted && !isCookieFreePage() ? 'blur(5px)' : 'none',
                    pointerEvents: !cookiesAccepted && !isCookieFreePage() ? 'none' : 'auto'
                }}>
                    <Header 
                        toggleLanguage={toggleLanguage} 
                        translateIcon={translateIcon} 
                        isCookieFreePage={!cookiesAccepted && isCookieFreePage()} 
                    />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/features" element={<Features />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/cgv" element={<CGV />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/cookies" element={<Cookies />} />
                        <Route path="/partners" element={<Partners />} />
                        <Route path="/sitemap" element={<SiteMap />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Footer showFullFooter={cookiesAccepted || !isCookieFreePage()} />
                </div>
                {!cookiesAccepted && !isCookieFreePage() && (
                    <CookieConsent 
                        language={language}
                        handleUserPreference={handleUserPreference}
                        cookiesAccepted={cookiesAccepted}
                    />
                )}
            </Router>
            <ToTopBtn />
        </CookieConsentProvider>
    );
}

export default App;
