import React from 'react';
import '../styles/Footer.css';

function Footer({ showFullFooter }) {
    return (
        <footer>
            {showFullFooter ? (
                <>
                    <div className="footer-content">
                        <div className="footer-links">
                            <h4>Liens utiles</h4>
                            <ul>
                                <li><a href="/cgv" target="_blank" rel="noopener noreferrer">Conditions Général de Vente</a></li>
                                <li><a href="/terms" target="_blank" rel="noopener noreferrer">Conditions d'utilisation</a></li>
                                <li><a href="/privacy" target="_blank" rel="noopener noreferrer">Politique de confidentialité</a></li>
                                <li><a href="/cookies" target="_blank" rel="noopener noreferrer">Politique relative à l'utilisation des cookies</a></li>
                                <li><a href="/sitemap" target="_blank" rel="noopener noreferrer">Plan du site</a></li>
                            </ul>
                        </div>
                        <div className="social-media">
                            <h4>Réseaux sociaux</h4>
                            <ul>
                                <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                                <li><a href="https://www.instagram.com/skaneat.off/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                                <li><a href="https://www.linkedin.com/company/skaneat/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                            </ul>
                        </div>
                    </div>
                    <p >&copy; 2025 SkanEat. Tous droits réservés.</p>
                </>
            ) : (
                <div className="footer-minimal">
                    <p>© 2025 SkanEat. Tous droits réservés.</p>
                </div>
            )}
        </footer>
    );
}

export default Footer;
